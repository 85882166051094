import axios from 'axios';
import { createStore } from 'vuex'
import * as api from "../services/Http"
import createPersistedState from 'vuex-persistedstate';


export default createStore({
  state: {
    user:{
      name:"",
      username:"",
      branch_id:"",
    },
    failedLogin: "",
    logged: "",
    count_sold :null,
    money_rec : null,
    report_yearly:[],
    count_available :null,
    bikes_available:[],
    bikes_sold:[]
  },
  getters: {},
  plugins:[createPersistedState()],
  mutations: {
    setName(state, value) {
      state.user.name = value;
    },
    setUsername(state, value) {
      state.user.username = value;
    },
    setUserBranch(state, value) {
      state.user.branch_id = value;
    },
    setLogged(state, value) {
      state.logged = value;
    },
    setFailedLogin(state, value) {
      state.failedLogin = value;
    },
    setMoneyRec(state,value){
      state.money_rec = value;
    },
    setReportYearly(state,value){
      state.report_yearly = value;
    },
    setAvailableBike(state,value){
      state.count_available = value;
    },
    setBikeAvailable(state,value){
      state.bikes_available = value;
    },
    setSoldBike(state,value){
      state.count_sold = value;
    },
    setBikeSold(state,value){
      state.bikes_sold = value;
    },
  },
  actions: {
    async getFinanceRecently({commit},monthYear){
      // console.log(api.month);
     await axios.get(api.base_url+"/investor/laporan-keuangan?key=1f09b7281d203fab784ee47a029a4265&kantor_cabang="+this.state.user.branch_id+"&tahun="+monthYear.year+"&bulan="+monthYear.month).then(
        function(response){
          // console.log(response.data.data.reportlength === 0);
          if(response.data.data.report_monthly.length === 0){
            commit('setMoneyRec',null);
          }else{
            const report = response.data.data.report_monthly[0].total;
            commit('setMoneyRec',report);
          }
            const reportYearly = response.data.data.report_yearly;
            // console.log(report);
            commit('setReportYearly',reportYearly);
          
          
        }
      )
    },
    
    async getBikesAvailable({commit}){
      await axios.get(api.base_url+"/investor/motor-tersedia?key=1f09b7281d203fab784ee47a029a4265&kantor_cabang="+this.state.user.branch_id)
      .then(function(response){
        if(response.data.data.length === 0){
          commit('setAvailableBike',null);
          commit('setBikeAvailable',null);
        }else{
          // console.log(response.data.data);
          const listBike = response.data.data;
          const countBike = response.data.data.length;
          commit('setAvailableBike',countBike);
          commit('setBikeAvailable',listBike);
        }    
      })
    },

    async getBikesSold({commit},monthYear){
      await axios.get(api.base_url+"/investor/motor-terjual?key=1f09b7281d203fab784ee47a029a4265&kantor_cabang="+this.state.user.branch_id)
      .then(function(response){
        if(response.data.data.length === 0){
          commit('setSoldBike',null);
          commit('setBikeSold',null);
        }else{
          // console.log(response.data.data);
          const listBike = response.data.data;
          const countBike = response.data.data.filter((motor : any)=>{
            return motor.tgl_jual.includes(monthYear.year+'-'+monthYear.month.id+'-')
        }).length;
          commit('setSoldBike',countBike);
          commit('setBikeSold',listBike);
        }
      })
    }
  },
  modules: {},
});
