import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import './assets/style.css'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
// import icon from 'vuetify/lib'
// import * as icons from 'vuetify/dist/'
import "@mdi/font/css/materialdesignicons.css";
import axios from 'axios'
import VueAxios from 'vue-axios'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi
      },
    },
  })
 
createApp(App).use(VueAxios,axios).use(store).use(router).use(vuetify).mount('#app')
