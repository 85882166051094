import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import { useStore } from 'vuex'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // component: LoginView
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')

  },
  {
    path: '/finance',
    name: 'finance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FinanceView.vue')
  },
  {
    path: '/dash',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DashView.vue')
  },
  {
    path: '/stock-motor',
    name: 'stock-motor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StockView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const isAuthenticated = JSON.parse(localStorage.getItem("authenticated") || "");

// const isAuthenticated = false;
// const store = useStore();
// const isLogged = store.state.logged;


router.beforeEach((to,from,next)=>{
//  console.log(to,from);
const isAuthenticated = localStorage.getItem("authenticated");
// console.log(isAuthenticated && isLogged);
//  next();

 if(to.name !== "home" && !isAuthenticated) next({name:"home"});
 if(to.name === "home" && isAuthenticated) next({name:"dashboard"});
 else next();
});

export default router
